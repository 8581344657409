
.special_modal .modal-content{
    background-color: rgba(255, 255, 255, 0.937);
    border: 2px solid red;
    border-radius: 15px;
 }
.header-empty {
    border: none;
}
.cart-header {
    text-align: center;
    margin-top: .7em;
}
.items-container {
    font-size: .9em;
    padding: .8em .3em;
    display: flex;
    justify-content:center;
    align-items: center;
    text-align: center;
    box-shadow: rgba(60, 60, 77, 0.1) 0px 1px 0px;
}

.items-container button {
    margin: auto .3em;
}

.flex1 {
    /* box-shadow: rgba(60, 60, 77, 0.1) 0px 1px 0px; */
    flex-basis: 23%;
    font-weight: 500;
}
.flex2 {
    flex-basis: 23%;
    font-style: italic;
}
.flex3 {
    flex-basis: 15%;
    font-weight: 500;
}
.flex4-6 {
    flex-basis: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.flex5 {
    flex-basis: 5%;
}

.flex7 {
    margin-left: 1em;
}
/* CSS */
.checkout{
    border: 1px solid rgba(3, 102, 214, 0.3);
    color: #41403e;
    font-size: .9em;
    transition: all 235ms ease-in-out;
    background-color: hsla(0, 0%, 91%, 0.895);
    border-radius: 5px;
    font-style: italic;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}
.checkout:hover {
  transform: translate3d(0, 2px, 0);
  box-shadow: rgb(255, 0, 0) 2px 8px 8px -5px !important;
}

.quantity {
    border: 1px solid rgba(3, 102, 214, 0.3);
    background-color: hsla(191, 74%, 91%, 0.214);
    border-radius: 5px;
    width: 23px;
    text-align: center;
    font-size: .9em;
    font-weight: 600;
}
.quantity-adjust {
    border-width: 1px;
    border-top-color: rgba(3, 102, 214, 0.3);
    border-left-color: rgba(3, 102, 214, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18px;
    width: 18px;
    padding-bottom: .1em;
    background-color: hsla(0, 0%, 91%, 0.895);
    transition: all 235ms ease-in-out;
}

.remove {
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 .1em 0;
    border-radius: 50px;
    border: 1px solid transparent;
    background-color: #ff8f8f35;
    transition: all 235ms ease-in-out;
    font-size: .8em;
}
.remove:hover {
    border: 1px solid rgba(3, 102, 214, 0.3);
    background-color: #ff000067;
}

.total {
    font-size: 1em;
}
@media (max-width: 400px) {
    .items-container button {
        margin: auto .1em;
    }
    .checkout {
        font-size: .9em;
    }
    .items-container {
        font-size: .75em;
    }
  }