.flyer-container {
  display: flex;
  margin-bottom: 3em;
}

.item-center {
  flex-grow: 1;
}

.item + .item {
  margin-left: 2%;
}
/* Flyer  */
.flyer-date {
  background-color: #ffffffc9;
  color: black;
  font-weight: 600;
  font-size: .9em;
  position: relative;
}
.flyer-date p {
  height: 1.5em;
  margin-bottom: 0;
}

.flyer-date div {
  background-color: #f00000;
  height: 100%;
  width: 5px;
  position: absolute;
}

/* poster image */
/* .flyer {
  width: 100%;
} */
.flyer-image {
  height: 20em;
  width: 20em;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.past-flyer {
  filter: grayscale(90%) opacity(.8);
  margin-top: .5em;
}

/* HEADLINER */ 
.headliner {
  text-shadow: 2px 2px 17px #ff0000f7;
  font-weight: 600;
  padding-bottom: 0;
  padding-top: 0;
}

.headliner a {
  cursor: pointer;
  padding: .2em 0 0;
  font-size: 1.4em;
  color: #ffffff;
  position: relative;
}

.headliner a:hover {
  color: #81bde3;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 1), 0 0 5px rgba(255, 255, 255, 0.8), 0 -4px 15px rgba(255, 255, 255, 0.5);
}

/* SUPPORT  */
.support {
  padding-top: .5em;
  padding-left: .85em;
}

.support a {
  font-weight: 500;
  text-shadow: 0px 0px 6px rgba(12, 143, 225, 0.566);
  cursor: pointer;
  padding: 0 .2em 0 .2em;
}
.support a:hover {
  color: #81bde3;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 1), 0 0 5px rgba(255, 255, 255, 0.8), 0 -4px 15px rgba(255, 255, 255, 0.5);
}

/* info */
.time {
  font-size: .9em;
  padding: 0 0 .5em 1em;
}

.clock {
  margin-right: 0.28em;
  margin-bottom: .15em;
}

.location {
  text-shadow: 0px 0px 6px rgba(12, 143, 225, 0.566);
  cursor: pointer;
  margin-bottom: .5em;
  padding-left: .8rem;
  padding-top: 0;
  font-size: .9em;
}
.location img {
  margin-right: 0.25em;
  margin-bottom: .15em;
  width: 15px;
  box-shadow: none;
}

.location a:hover {
  color: rgb(188, 188, 188);
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 1), 0 0 5px rgba(255, 255, 255, 0.8), 0 -4px 15px rgba(255, 255, 255, 0.5);
}

.flyer-container li {
  color: rgb(188, 188, 188);
  background-color: transparent;
  border: none;
}
.flyer-container a {
  display: inline-block;
}

/* CSS */
.tickets,
.tickets:after {
  margin-bottom: 1em;
  font-style: italic;
  width: 90px;
  height: 35px;
  background: linear-gradient(45deg, transparent 5%, #ff003cba 5%);
  border: 0;
  color: rgb(237, 237, 237);
  box-shadow: 6px 0px 0px #34d8eb;
  outline: transparent;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.tickets:after {
  --slice-0: inset(50% 50% 50% 50%);
  --slice-1: inset(80% -6px 0 0);
  --slice-2: inset(50% -6px 30% 0);
  --slice-3: inset(10% -6px 85% 0);
  --slice-4: inset(40% -6px 43% 0);
  --slice-5: inset(80% -6px 5% 0);
  
  content: 'swag';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, transparent 3%, #34d8eb 3%, #34d8eb 5%, #FF013C 5%);
  text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #34d8eb;
  clip-path: var(--slice-0);
}

.tickets:hover:after {
  animation: 1s glitch;
  animation-timing-function: steps(2, end);
}

@keyframes glitch {
  0% {
    clip-path: var(--slice-1);
    transform: translate(-20px, -10px);
  }
  10% {
    clip-path: var(--slice-3);
    transform: translate(10px, 10px);
  }
  20% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 10px);
  }
  30% {
    clip-path: var(--slice-3);
    transform: translate(0px, 5px);
  }
  40% {
    clip-path: var(--slice-2);
    transform: translate(-5px, 0px);
  }
  50% {
    clip-path: var(--slice-3);
    transform: translate(5px, 0px);
  }
  60% {
    clip-path: var(--slice-4);
    transform: translate(5px, 10px);
  }
  70% {
    clip-path: var(--slice-2);
    transform: translate(-10px, 10px);
  }
  80% {
    clip-path: var(--slice-5);
    transform: translate(20px, -10px);
  }
  90% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 0px);
  }
  100% {
    clip-path: var(--slice-1);
    transform: translate(0);
  }
}

@media (max-width: 767px) {
  .flyer-container {
    flex-direction: column;
    text-align: center;
    margin-bottom: 0;
  }
  .flyer-container li {
    padding-left: 0;
    padding-right: 0;
    font-size: .9em;
  }
  .flyer-image {
    max-width: 100%;
    height: auto;
  }
  .headliner {
    padding-top: 1em;
  }
}
@media (max-width: 400px) {
  .flyer-container {
    font-size: .95em;
  }
}