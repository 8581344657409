.events-container {
    color: white;
}
.events-container > p {
    margin: 0 0 5px;
    font-size: .9em;
}

.nil-events {
    margin-bottom: 1.5em;
    text-align: center;
}
.router-title-past {
    margin: .75em 0 0 0 !important;
}
.event-outer-container {
    padding: 0 1.5em;
    margin-top: 1em;
}

/* CSS */
.past-events {
    border: 1px solid transparent;
    border-radius: 10px;
    cursor: pointer;
    color: white;
    background-color: #70707000;
}
.past-events:hover {
    border: 1px solid red;
}

@media (max-width: 767px) {
    .events-container p{
      text-align: center;
    }
    .event-outer-container {
        margin-top: .5em;
        padding-left: .5em;
        padding-right: .5em;
    }
  }