body {
  box-sizing: border-box;
  min-height: 100%;
  background-image: linear-gradient(to bottom, #121111, #151415, #181718, #1a1a1a, #1d1d1d, #1d1d1d, #1d1d1d, #1d1d1d, #1a1a1a, #181718, #151415, #121111);
}
html {
  height: 100%;
}
a {
  text-decoration: none !important;
  color: inherit;
  transition: 500ms;
  cursor: pointer;
}
