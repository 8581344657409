.sidebar.open {
  left: 0;
}
.sidebar-toggle {
  z-index: 0;
  background: url('../../assets/images/soundcloud.png');
  border: none;
  background-size: cover;
  position: absolute;
  right: 0;
  height: 30px;
  width: 30px;
  transition: right 0.3s ease-in-out;
}
.sidebar-toggle.open {
  right: 220px;
}

.soundcloud-outer-container {
  overflow-x: hidden;
  position: absolute;
  width: 220px;
  right: -220px;
}
.soundcloud-outer-container.open {
  overflow-x: visible;
  right: 0;
  transition: right 0.3s ease-in-out;
}

.soundcloud-container {
  display: flex;
  position: absolute;
  padding: 0 20px 0;
  background-color: #30303e;
  flex-direction: column;
  padding-bottom: 0;
  border-bottom-left-radius: 5px;
}

.soundcloud-inner-container {
  margin-bottom: 25px;
}

.dj-title {
  text-align: center;
  padding: 5px;
  color: white;
  margin-bottom: 7px;
  font-family: cursive;
}

.soundcloud-name {
  background-color: #ffffffc9;
  font-size: .8em;
  padding: 3px;
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: rgb(66, 65, 65);
}