.outer-container{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
}
.inner-container {
    max-width: 1000px;
    width: 100%;
    padding: 0 30px 20px;
    margin-bottom: 5%;
}
.router-title {
    color: white;
    border-left: 2px solid red;
    padding-left: .7em;
    margin: 30px 0 20px 0;
    font-size: 1.5em;
}

@media (max-width: 767px) {
    .router-title {
        font-size: 1.2em;
    }
    .inner-container {
        padding: 0 10px 20px;
    }
  }