.about-container {
    color: white !important;
}

.box {
    display: flex;
    justify-content:space-around;
    align-items: center;
    text-align: center;
}

.box p {
    width: 45em;
}

.abt-break {
    margin: .5em auto;
    width: 25%;
    border: 0;
    height: 1px;
    margin-bottom: 1em;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(255, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

.abt-image {
    margin-top: 1.5em;
    width: 50%;
    min-width: 325px;
    max-width: 400px;
}


@media (max-width: 767px) {
}