@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);

.contact-container {
  color: white !important;
}
.contact-container h2 {
  text-align: center;
}
.contact-inner-container {
  display: flex;
}

.contact-container h5 {
  border-left: 1px solid red;
  padding-left: 5px;
  margin-top: 1em;
  text-align: left;
  padding-left: .7em;
}

.contact-container a {
  font-style: oblique;
}
.booking-info {
  font-size: 1.1em;
  flex-basis: 70%;
  margin-bottom: 1em;
}

.booking-sub-title {
  margin-top: 3em;
}
.booking-sub-info a {
  font-weight: 100;
}

.booking-sub-info {
  text-align: center;
  padding-left: .5em;
  margin-bottom: .5em;
}
.booking-sub-info p {
  margin-bottom: 0;
  font-size: .9em;
}
.booking-sub-info hr {
  margin: .5em auto;
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(255, 0, 0, 0.75), rgba(0, 0, 0, 0));
}
.booking-sub-info em {
  border-bottom: rgba(236, 62, 62, 0.413) solid 2px;
}
.booking-sub-info b {
  color: rgba(236, 62, 62, 0.413);
}

.inline-social {
  bottom: 1px;
}
form { 
  padding-left: 3em;
  max-width:400px; 
}

.feedback-input {
  color: white;
  font-family: Helvetica, Arial, sans-serif;
  border-radius: 5px;
  line-height: 20px;
  background-color: rgba(255, 255, 255, 0.064) !important;
  border:2px solid #bb2b2b;
  transition: all 0.3s;
  padding: 5px;
  margin-bottom: 15px;
  width:100%;
  box-sizing: border-box;
  outline:0;
}
::placeholder { 
  color: rgb(191, 191, 191);
  opacity: 1; 
}


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  transition: background-color 5000s;
  -webkit-text-fill-color: #fff !important;
}
.feedback-input:focus { border:2px solid #e97a7a; }

textarea {
  height: 150px;
  line-height: 150%;
  resize:vertical;
}

[type="submit"] {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  width: 100%;
  background:#bb2b2b;
  border-radius:5px;
  border:0;
  cursor:pointer;
  color:white;
  padding-top:10px;
  padding-bottom:10px;
  transition: all 0.3s;
  margin-top:-4px;
  font-weight:700;
}
[type="submit"]:hover { background:#e97a7a; }

@media (max-width: 767px) {
  .contact-inner-container {
    font-size: .95em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .contact-container h5 {
    text-align: center;
  }

  .booking-sub-info {
    border: none;
  }
  .booking-info {
    padding-left: 0;
    text-align: center;
    margin-left: 0;
  }
  form { 
    padding-left: 0;
  }
}