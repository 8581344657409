/* Header */
.h-image {
  display: flex;
  justify-content: center;
  background: url(../../assets/images/background.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
} 
.h-image img {
  margin-top: 1em;
  width: 450px;
  height: 160px;
}

/* NAVBAR */
.nav-container {
  max-width: 1020px;
  margin: 0 !important;
}

.navbar-collapse {
  position: relative;
}

nav {
  font-family: "Roboto", sans-serif;
  font-size: 1.1em;
  font-weight: 600;
  position: sticky !important;
  z-index: 2;
  background-color: #30303e;
  border-top: 1px solid rgba(128, 125, 125, 0.401);
  border-bottom: 1px solid rgba(128, 125, 125, 0.401);
  top: 0;
}
.navbar-toggler {
  padding: 0;
  border: none;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.nav-links {
  justify-content: center;
  padding: .3em;
}

.nav-link {
  margin: auto 10px;
  padding: 0;
  transition: 500ms;
  color: #ffffff !important;
}
.nav-link:hover {
  color: #f33b3b !important;
}
.nav-link.active {
  color: #f33b3b !important;
}

/* social */
.social-small {
  display: none !important;
}
.social {
  border-radius: 100%;
  position: absolute !important;
}
.facebook {
  right: 10px;
}
.insta {
  right: 50px;
}
.linktree {
  right: 90px;
}

.shopping-cart {
  right: 130px;
}

.social:hover {
  background-color: #f33b3b;
  box-shadow: rgb(255, 0, 0) 0px 0px 20px;
}

@media (max-width: 767px) {
  .social-small {
    display: inline !important;
  }
  .social-large {
    display: none !important;
  }
}
@media (max-width: 600px) {
  .h-image img {
    width: 300px;
    height: 105px;
  }
  .nav-link {
    padding: 3px;
  }
}

footer {
  position: fixed;
  margin: 0;
  bottom: 0;
  height: 20px;
  width: 100%;
  background-color: #30303e;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  font-size: 0.7em;
  z-index: 1;
}
footer p {
  color: #b4b3b3;
  border-top: 1px solid rgba(128, 125, 125, 0.401);
  margin-left: 10px;
}